import React, {useState} from "react";
import ReactMarkdown from 'react-markdown';
import uuid from "react-native-uuid";
import Linkify from "./Linkify";
import {appendClassName} from "@elastic/react-search-ui-views/es/view-helpers";

function ZIResult(props) {
    const [details, setDetails] = useState(false);
    const toggleDetails = () => {
        if (!details) setDetails(true);
        else setDetails(false);
    }

    function getFieldType(result, field, type) {
        if (result[field]) return result[field][type];
    }

    function getRaw(result, field) {
        return getFieldType(result, field, "raw");
    }

    function getAddresses(result) {
        let addresses = "<ul>";
        const tmp = getRaw(result, "addresses").split(";");

        tmp.forEach(function (address) {
            addresses += "<li>" + address + "</li>";
        });
        return addresses + "</ul>";
    }

    function getImageUrl(image) {
        return "https://www.research.uzh.ch/apps/platforms/upload/" + image.line;
    }

    return <li key={uuid.v1()} className={appendClassName("sui-result", props.className)} {...props.rest}>
        <div className="sui-result__header">
            {props.child.props.result.name.raw && (
                <div className={"platform_name sui-result__title sui-result__title-link"}
                     dangerouslySetInnerHTML={{__html: getRaw(props.child.props.result, "name") + " (" + getRaw(props.child.props.result, "abbreviation") + ")"}}
                     target="_self"
                     onClick={toggleDetails}
                     rel="noopener noreferrer"
                     key={uuid.v1()}
                />
            )}
            {props.child.props.result.national_research_institution && getRaw(props.child.props.result, "national_research_institution") === true && (
            <div className={"national_research_institution"}>
                    <p className={"image_name"}><img src={"switzerland.png"} key={uuid.v1()} alt="Nationale Forschungseinrichtung"/></p>
            </div>
            )}
            {props.child.props.result.tpf_label && getRaw(props.child.props.result, "tpf_label") === true && (
            <div className={"tpf_label"}>
                    <p className={"image_name"}><img src={"quality.png"} key={uuid.v1()} alt="TPF"/></p>
            </div>
            )}
        </div>
        {props.child.props.result.short_text && (
            <div className={"platform_short_text short_text"} key={uuid.v1()}>
                <ReactMarkdown>{getRaw(props.child.props.result, "short_text")}</ReactMarkdown>
            </div>
        )}
        {((!details && props.details) || (details && !props.details)) && <div className="sui-result__details">
            {props.child.props.result.images && getRaw(props.child.props.result, "images") !== null && (
                <div className={"images"}>
                    {getRaw(props.child.props.result, "images").split(";").map((line) => (
                        <img key={uuid.v1()} className="image" alt="" src={getImageUrl({line})}/>
                    ))}
                </div>
            )}
            <hr/>
            <h3>Erweiterte Angaben</h3>
            {props.child.props.result.description && getRaw(props.child.props.result, "description") !== null && (
                <div className={"description"}>
                    <strong>Beschreibung</strong>:
                    <ReactMarkdown>{Linkify(getRaw(props.child.props.result, "description"))}</ReactMarkdown>
                </div>
            )}
            {props.child.props.result.services && getRaw(props.child.props.result, "services") !== null && (
                <div className={"services"}>
                    <strong>Leistung(en)</strong>:
                    <ReactMarkdown>{Linkify(getRaw(props.child.props.result, "services"))}</ReactMarkdown>
                </div>
            )}
            <hr/>
            <h3>Standorte</h3>
            {props.child.props.result.addresses && getRaw(props.child.props.result, "addresses") !== null && (
                <div className={"addresses"}>
                    <span dangerouslySetInnerHTML={{__html: getAddresses(props.child.props.result)}}/>
                </div>
            )}
            <hr/>
            <p className={"small_space"}/>
            {props.child.props.result.website && getRaw(props.child.props.result, "website") !== null && (
                <div className={"website"}>
                    <strong>Webseite</strong>: <span dangerouslySetInnerHTML={{__html: Linkify(getRaw(props.child.props.result, "website"))}}/>
                </div>
            )}
            {props.child.props.result.social_media && getRaw(props.child.props.result, "social_media") !== null && (
                <div className={"social_media"}>
                    <strong>Social Media</strong>: <span dangerouslySetInnerHTML={{__html: Linkify(getRaw(props.child.props.result, "social_media"))}}/>
                </div>
            )}
        </div>}
    </li>
}

export default ZIResult;