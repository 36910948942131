import React from "react";
import {useTranslation} from "react-i18next";
import {
    ErrorBoundary,
    Paging,
    SearchProvider,
    WithSearch
} from "@elastic/react-search-ui";
import {Layout, MultiCheckboxFacet} from "@elastic/react-search-ui-views";
import buildRequest from "./buildRequest";
import runRequest from "./runRequest";
import applyDisjunctiveFaceting from "./applyDisjunctiveFaceting";
import buildState from "./buildState";
import ZIResultsPerPage from "./components/ZIResultsPerPage";
import ZIPageInfo from "./components/ZIPageInfo";
import ZISearchBox from "./components/ZISearchBox";
import ZIResults from "./components/ZIResults";

import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./search.css";
import ZIFacet from "./components/ZIFacet";

const config = {
    debug: false,
    hasA11yNotifications: true,

    onResultClick: () => {
        /* Not implemented */
    },
    onAutocompleteResultClick: async ({documentId, query, ...args}) => {
        /* Not implemented */
    },
    onAutocomplete: async ({searchTerm}) => {
        const requestBody = buildRequest({searchTerm});
        const json = await runRequest(requestBody);
        const state = buildState(json);

        // add preview url
        for (let key in state.results) {
            const tmp = state.results[key].name.raw;

            if (state.results[key].name) {
                state.results[key].url = state.results[key].name;
                state.results[key].url.raw = "?q=" + state.results[key].name.raw;
                
                if (!state.results[key].url.snippet) {
                    state.results[key].url.snippet = tmp;
                }
            }
        }

        return {
            autocompletedResults: state.results
        };
    },
    onSearch: async state => {
        const {resultsPerPage} = state;
        const requestBody = buildRequest(state);
        const responseJson = await runRequest(requestBody);
        const responseJsonWithDisjunctiveFacetCounts = await applyDisjunctiveFaceting(
            responseJson,
            state,
            ["research_areas", "service_types"]
        );
        return buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage);
    },
    disjunctiveFacets: ["research_areas", "service_types"],
    initialState: {searchTerm: window.location.href.match("/show/") ? "" : "*", resultsPerPage: 10},
};

export default function App() {
    const {t, ready} = useTranslation();

    function resetFilter() {
        setTimeout(function (){
            window.location = window.location.href.split(/[?#]/)[0];
        }, 500);
    }

    if (!ready) return (<div></div>);
    return (
        <SearchProvider config={config}>
            <WithSearch mapContextToProps={({wasSearched}) => ({wasSearched})}>
                {({wasSearched}) => (
                    <div className="App">
                        <ErrorBoundary>
                            <Layout
                                header={
                                    <ZISearchBox/>
                                }
                                sideContent={
                                    <div>
                                        {wasSearched && <button id={"resetFacets"} className="button" onClick={resetFilter}>{t("reset")}</button>}
                                        {wasSearched && <ZIFacet show={20} field="research_areas" label="Research Area" view={MultiCheckboxFacet} filterType="any"/>}<br/>
                                        {wasSearched && <ZIFacet show={20} field="service_types" label="Types of services" view={MultiCheckboxFacet} filterType="any"/>}
                                    </div>
                                }
                                bodyContent={
                                    <div>
                                        {wasSearched && <ZIResults/>}
                                    </div>
                                }
                                bodyHeader={
                                    <React.Fragment>
                                        {wasSearched && <ZIPageInfo/>}
                                        {wasSearched && <ZIResultsPerPage/>}
                                    </React.Fragment>
                                }
                                bodyFooter={<Paging/>}
                            />
                        </ErrorBoundary>
                    </div>
                )}
            </WithSearch>
        </SearchProvider>
    );
}
