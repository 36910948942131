import React from "react"

const Linkify = function Linkify(inputText) {
    inputText = (inputText !== null) ? inputText.toString() : '';
    //URLs starting with http://, https://, or ftp://
    let replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    let replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with www. (without // before it, or it'd re-link the ones done above)
    let replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto: links
    let replacePattern3 = /(([a-zA-Z0-9_\-.]+)@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,6})+?(?:\.[a-zA-Z]{2,6})?)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    //Change phone numbers to tel: links
    let replacePattern4 = /((\+)?([0-9\s]{10,20}))/gim;
    replacedText = replacedText.replace(replacePattern4, " <a href='tel:$1'>$1</a>");

    return replacedText
}

export default Linkify