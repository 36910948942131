import React from "react";
import {ResultsPerPage} from "@elastic/react-search-ui";
import Select from "react-select";

function ZIResultsPerPage(props) {
    return <ResultsPerPage
        view={({
                   onChange,
                   selectedOption,
                   show = props["show"],
               }) => (
            <div className="">
                <div className="sui-results-per-page__label">{show}</div>
                <Select
                    id={"results-per-page"}
                    key={"results-per-page"}
                    placeholder={""}
                    defaultValue={{label: 10, value: 10}}
                    className="sui-select sui-select--inline"
                    classNamePrefix="sui-select"
                    value={selectedOption}
                    onChange={o => onChange(o.value)}
                    options={[{label: 10, value: 10}, {label: 20, value: 20}, {label: 50, value: 50}]}
                    isSearchable={false}
                />
            </div>)}
    />;
}

export default ZIResultsPerPage;