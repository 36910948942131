
function getFilterFacet(aggregations, fieldName, options, labels) {
    let data = [];
    options.forEach(function (item, index) {
        data[index] = {value: options[index], label: labels[index], count: aggregations[fieldName].buckets[options[index]].doc_count};
    });

    if (
        aggregations &&
        aggregations[fieldName] &&
        aggregations[fieldName].buckets
    ) {
        return [
            {
                field: fieldName,
                type: "value",
                data: data,
            }
        ];
    }
}

export default function buildStateFacets(aggregations) {
    const research_areas_fields = ["natural_science_biomedicine_chemistry", "natural_science_physics_geoscience", "arts_law", "economy_law"];
    const research_areas_labels = ["Natural Science – Biomedicine / Chemistry", "Natural Science – Physics / Geoscience", "Arts and Humanities", "Economy and Law"];
    const research_areas = getFilterFacet(aggregations, "research_areas", research_areas_fields, research_areas_labels);

    const service_types_fields = ["supported_user_lab", "os_acquire_data", "os_analyze_data", "training", "access_data_base", "engineering_production", "testing", "non_human_sample_care"];
    const service_types_labels = ["Supported User Lab", "Operator Service to Acquire Data", "Operator Service to Analyze and Illustrate Data", "Training", "Access to Data Base", "Engineering and Production of Reagents", "Tools and Devices", "Testing Persons and Human Specimen Management", "Non-human Sample Care and Storage like Animals, Organoids, Cells, Plants"];
    const service_types = getFilterFacet(aggregations, "service_types", service_types_fields, service_types_labels);

    const facets = {
        ...(research_areas && {research_areas}),
        ...(service_types && {service_types}),
    };
    if (Object.keys(facets).length > 0) {
        return facets;
    } else {
        return {};
    }
}
