export default async function runRequest(body) {
  const response = await fetch(
    "https://www.uzh.ch/cgi-bin/esproxy/research_platforms_proxy.py",
    {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(body),
    }
  );

  return response.json();
}
