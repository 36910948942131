import React from "react";
import {appendClassName} from "@elastic/react-search-ui-views/es/view-helpers";
import {Results} from "@elastic/react-search-ui";
import ZIResult from "./ZIResult";
import uuid from "react-native-uuid";

function ZIResults() {
    return <Results
        view={({children, className, ...rest}) => (
            <ul className={appendClassName("sui-results-container", className)}>
                {children.map(child => (
                    <ZIResult
                        details={children.length === 1}
                        key={uuid.v1()}
                        child={child}
                        className={className}
                        rest={rest}>
                    </ZIResult>
                ))}
            </ul>)}
    />;
}

export default ZIResults;