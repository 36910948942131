import React from 'react';
import {Facet} from "@elastic/react-search-ui";
import {useTranslation} from "react-i18next";
import uuid from 'react-native-uuid';

function ZIFacet(props) {
    const {t} = useTranslation();

    return <Facet
        field={props.field}
        label={props.label}
        filterType="any"

        view={({options, label, onRemove, onSelect}) => (
            <div className="sui-multi-checkbox-facet"><h3>{label.toUpperCase()}</h3>
                {options.map(option => {
                    const checked = option.selected;

                    if (option.count < 1) {
                        return false;
                    }
                    return (
                        <label key={uuid.v1()} className="sui-multi-checkbox-facet__option-label">
                            <div className="sui-multi-checkbox-facet__option-input-wrapper">
                                <input
                                    type="checkbox"
                                    className="sui-multi-checkbox-facet__checkbox"
                                    checked={checked}
                                    onChange={() =>
                                        checked ? onRemove(option.value) : onSelect(option.value)
                                    }
                                />
                                <span className="sui-multi-checkbox-facet__input-text">{t(option.label)}</span>
                            </div>
                            <span className="sui-multi-checkbox-facet__option-count">{option.count.toLocaleString("en")}</span>
                        </label>
                    );

                })}
            </div>
        )}
    />;
}

export default ZIFacet;
