import React from "react";
import {PagingInfo} from "@elastic/react-search-ui";
import {useTranslation} from "react-i18next";

function ZIPageInfo() {
    const {t} = useTranslation();

    return <PagingInfo
        view={({start, end, searchTerm, totalResults}) => (
            <div className="paging-info">
                {t("showing")} <strong>{start}</strong> - <strong>{end}</strong> {t("of")} <strong>{totalResults}</strong>
                {searchTerm && searchTerm !== '*' && !searchTerm.match('[A-Z0-9]{32}') && (
                    <>
                        {" "}
                        {t("for")}: <strong><em>{searchTerm}</em></strong>
                    </>
                )}
            </div>
        )}
    />;
}

export default ZIPageInfo;