import React from "react";
import {SearchBox} from "@elastic/react-search-ui";
import {useTranslation} from "react-i18next";

function ZISearchBox() {
    const {t} = useTranslation();

    return <SearchBox
        autocompleteMinimumCharacters={3}
        autocompleteResults={{
            linkTarget: "_top",
            sectionTitle: t("results"),
            titleField: "name",
            urlField: "url",
            shouldTrackClickThrough: true,
        }}
        autocompleteSuggestions={true}
        inputView={({getAutocomplete, getInputProps, searchTerm}) => (
            <>
                <div className="sui-search-box__wrapper">
                    <input
                        {...getInputProps({
                            placeholder: "Suchen",
                            value: (searchTerm === '*') ? '' : searchTerm
                        })}
                    />
                    {getAutocomplete()}
                </div>
                <input className={"button sui-search-box__submit"}
                       type={"Submit"}
                       value={t("search")}
                       readOnly={true}
                />
            </>
        )}
    />;
}

export default ZISearchBox;