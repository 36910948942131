import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

let lang = (window.location.href.match('en.html')) ? "en" : "de";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    backend: {
      loadPath:
        "https://www.research.uzh.ch/apps/platforms/locales/" + lang + "/{{ns}}.json",
    },
    fallbackLng: "de",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
